<template>
  <div class="home">
    <div class="row">
      <div class="col-md-12" v-if="$root.$session.usuario">
         Olá, {{$root.$session.usuario.strNome}}
      </div>
    </div>
  </div>
</template>

<script>

import { EdButton, EdForm, EdModal, EdCollapse } from "@/components/common/form";

export default {
  name: "pageIndex",
  props: {},
  components: {EdButton, EdForm, EdModal, EdCollapse },
  mounted() {},
  data() {
    return {
      e1: 1,
      docs:[{nome:'Doc 1'},{nome:'Doc 2'}]
    };
  },
  methods: {},
};
</script>

